import React, {useEffect, useState} from 'react';
import './App.css';
import "./CSS/Main.css";
import Payment from "./Components/Sections/Payment";
import Main from "./Components/Sections/Main";


function AppMain() {
    let projectInLocalStorage = null
    try {
        projectInLocalStorage = JSON.parse(localStorage.getItem("projetsSoutenus"))
    } catch (e) {

    }
    const [projetsSoutenus, setProjetsSoutenus] = useState(projectInLocalStorage || []);
    // {title:"Dons libres", isMandatory:true, montant:"5"},
    if (!projetsSoutenus.some((elem) => elem.id === -1)) {
        setProjetsSoutenus((prevState) => [{id:-1, title:"Dons libres", isMandatory:true, montant:5}, ...prevState])
    }
    const [headerAccueil, setHeaderAccueil] = useState(false);

    useEffect(() => {
        if (projetsSoutenus.length > 0) localStorage.setItem("projetsSoutenus", JSON.stringify(projetsSoutenus))
    })

    return (<div className="App">
        <Main setProjetsSoutenus={setProjetsSoutenus} headerAccueil={headerAccueil}
              setHeaderAccueil={setHeaderAccueil}/>
        <Payment projetsSoutenus={projetsSoutenus}
                 setProjetsSoutenus={setProjetsSoutenus}
                 setHeaderAccueil={setHeaderAccueil}
        />
    </div>)
}

export default AppMain;
