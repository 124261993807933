import React from "react";
import "../../CSS/Categories.css";
import {categories} from "./Main";

export default class Categories extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isActive: false,
            categorieHovered: 1
        }
    }

    handleHover = (e) => {
        this.setState({categorieHovered: parseInt(e.target.id.slice(-1))})
    }

    handleHoverLeave = () => {
        this.setState({categorieHovered: 0});
    }

    render() {
        return <div id="categories">
            <div className="categories-presentation">
                Cliquez sur une catégorie pour en apprendre plus sur nos projets
                <br/>
                Soutenez nos projets, soutenez-nous.
            </div>
            {categories.map((elem, i) => {
                    return (
                        <div className={"categorie"} id={"categorie" + (i + 1)} onMouseEnter={this.handleHover}
                             key={i}
                             style={{backgroundImage: "url(" + elem.background + ")"}}
                             onMouseLeave={this.handleHoverLeave}
                             onClick={() => this.props.onCategorieClick(i + 1 + "")}>
                            <div className="categorieTitle">
                                {elem.name}
                            </div>
                            <br/>
                            <div className="sousTitre" hidden={this.state.categorieHovered !== (i + 1)}>
                                {elem.description}
                            </div>
                        </div>
                    )
                }
            )}

            <div className="categorie1-contour"/>
            <div className="categorie2-contour"/>
            <div className="categorie4-contour"/>

        </div>
    }
}
