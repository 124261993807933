import React, {useState} from "react";
import Button from "@material-ui/core/Button";
import {Link} from "@material-ui/core";


function PaypalButton({amount, ...props}) {
    amount = parseInt(amount * 10) / 10
    const [paid, setPaid] = React.useState(false);
    const [error, setError] = React.useState(null);

    const paypalRef = React.useRef();

    React.useEffect(() => {
        console.log("effect")
        window.paypal?.Buttons({
            createOrder: (data, actions) => {
                return actions.order.create({
                    intent: "CAPTURE",
                    purchase_units: [
                        {
                            description: "Your description",
                            amount: {
                                currency_code: "EUR",
                                value: amount,
                            },
                        },
                    ],
                });
            },
            onApprove: async (data, actions) => {
                const order = await actions.order.capture();
                setPaid(true);
                console.log(order);
            },
            onError: (err) => {
                //   setError(err),
                console.log("erreur")
                console.error(err);
            },
        })
            .render(paypalRef.current);
    }, []);

    return (<>
        {paid && <div style={{color: "green"}}>Paiement réussi!</div>}
        {error !== null && <div style={{color: "red"}}>Erreur lors du paiement</div>}
        <div style={{margin: "auto"}} ref={paypalRef}/>
    </>)
}

export default class PaymentButton extends React.Component {

    constructor({amount, isShown, ...props}) {
        super({amount, isShown, ...props});
        this.state = {
            isShown: false
        }
        this.isShown = isShown;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.isShown = false;
    }

    render() {
        console.log(window.paypal)
        if (window.paypal === undefined) {
            return (<div style={{margin: "auto"}} className={"payment-button"}>
                <Link href="https://semenka.org/faire-un-don/"><Button>Soutenir</Button></Link>
            </div>)
        }

        return (<div style={{margin: "auto"}} className={"payment-button"}>
            {!this.isShown && <Button onClick={() => {
                this.isShown = true;
                this.forceUpdate();
            }}>Faire un don ({this.props.amount})</Button>}
            {this.isShown && <PaypalButton amount={this.props.amount}/>}
        </div>)
    }
}