import React from "react";
import "../../CSS/ProjetArticle.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import {ReactComponent as BackArrow} from "../../Ressources/back_arrow.svg";
import {ReactComponent as GiftHeart} from "../../Ressources/gift_heart_white.svg";
import Button from "@material-ui/core/Button";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';


export default class ProjetArticle extends React.Component {

    HelpButtons = () => {
        return (<div className="article-soutien-div">
            <Button
                variant="contained"
                color="primary"
                className="article-soutien-mui-button"
                startIcon={<GiftHeart style={{width: "2em", height: "2em"}}/>}
                onClick={
                    () => this.props.setProjetsSoutenus((prevState) => {
                        for (let i = 0; i < prevState.length; i++) {
                            if (prevState[i].id === this.props.articleProjet.id) {
                                if (prevState[i].montant !== 5) {
                                    prevState[i].montant = 5;
                                    return prevState;
                                } else {
                                    return prevState;
                                }
                            }
                        }
                        return [...prevState, {
                            id: this.props.articleProjet.id,
                            title: this.props.articleProjet.title,
                            montant: 10,
                            isMandatory: false
                        }]
                    })}
            >
                Nous soutenir
            </Button>
        </div>)
    }

    render() {
        return <div style={{display:"flex"}}>
            <button onClick={this.props.onBackClick}
                    className={"back-button-fixed"}>
                <ArrowBackIosIcon className={"back-button-logo"} style={{fontSize: 50}}/>
            </button>

            <div className={"projet-article"}>
                <button onClick={this.props.onBackClick}
                        className={"back-button"}>
                    <BackArrow className={"back-button-logo"}/>
                </button>
                <div className={"article-head"}
                     style={{background: "url(" + this.props.articleProjet.picture + "), rgba(0,0,0,.2"}}>
                    <div className="article-title">
                        {this.props.articleProjet.title}
                    </div>
                    <div className="article-description">
                        {this.props.articleProjet.description}
                    </div>
                </div>

                {Object.entries(this.props.articleProjet).length !== 0 ?
                    this.props.articleProjet.paragraphs.map((elem, i) => {
                        return <div key={i}>
                            <div className="paragraph-title">
                                {elem.title}
                            </div>
                            <div className={"paragraph-content"}
                                 style={{flexDirection: (elem.hasPicture && elem.pictureSide === "right") ? "row" : "row-reverse"}}>
                                <div className="paragraph-text">
                                    {elem.content}
                                </div>
                                {elem.hasPicture ? (<div style={{
                                        backgroundImage: "url(" + elem.picture + ")",
                                    }} className={"paragraph-picture"}/>)
                                    :
                                    []}
                            </div>
                            <hr style={{
                                margin: "1em 5em 3em 5em",
                                border: "1.2px solid var(--semenka-blue)",
                                backgroundColor: "var(--semenka-blue)"
                            }}/>
                        </div>
                    }) : (
                        <CircularProgress style={{margin: "10% 50% 10% 50%"}}/>)
                }
                {Object.entries(this.props.articleProjet).length !== 0 ?
                    this.HelpButtons()
                    : []}
            </div>
        </div>
    }

}