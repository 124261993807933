import React from "react";
import {ReactComponent as DiscoButton} from "../../Ressources/disconnect_button.svg";

export default () => {
    const handleClick = () => {
        localStorage.removeItem("tokenAdmin");
        // console.log("deconnect");
        document.location.reload();
    }

    return <div onClick={handleClick} style={{
        position:"fixed",
        top:"1%",
        right:"1%",
        padding:"10px",
        backgroundColor:"var(--semenka-blue)",
        opacity:"0.6",
        height:"5vh",
        width:"5vw",
        borderRadius:"10%",
        boxShadow:"5px 5px 5px grey",
        zIndex:5
    }}>
        <DiscoButton style={{maxHeight:"5vh",maxWidth:"5vh"}}/>
    </div>
}