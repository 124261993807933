import React from 'react';
import "../../CSS/Payment.css";
import Slider from "@material-ui/core/Slider";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import {IconButton} from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import PaymentButton from "../Utils/PaymentButton";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";


const PriceSlider = (props) => {
    let {value, setValue} = props;
    const maxVal = 2000;
    const handleInputChange = (event) => {
        setValue(event.target.value);
    };
    const handleSliderChange = (event, newValue) => {
        setValue(newValue);
    }

    return (<div className={"payment-slider-div " + (parseInt(value) > maxVal ? "over-val" : "")}>
        <Input
            value={value}
            margin="dense"
            onChange={handleInputChange}
            inputProps={{
                step: 0.5,
                min: 0,
                max: maxVal,
                type: 'number',
            }}
            endAdornment="€"
            className={"payment-input"}
            style={{minWidth: "6em!important"}}
        />
        <div className={"payment-slider"}>
            <Slider onChange={handleSliderChange}
                    aria-labelledby="input-slider" value={value} max={maxVal}
                    color="secondary"
            />
        </div>

    </div>)
}

class Payment extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            projectList: props.projetsSoutenus
        };
    }

    totalAsString = (projetsSoutenus) => {
        let total = this.total(projetsSoutenus);
        // console.log(total)
        return parseInt(total) + "€" + (total % 1 === 0 ? "00" : (total % 1 + 0.01).toString().slice(2, 3) + "0");
    }

    total = (projetsSoutenus) => (projetsSoutenus.reduce((total, projet) => (total += parseFloat(projet.montant)), 0.0));

    valueSetter = (index) => {
        return (value) => {
            this.setState((prevState) => {
                prevState.projectList[index].montant = value;
                return prevState;
            }, () => localStorage.setItem("projetsSoutenus", JSON.stringify(this.state.projectList)))

        }
    }

    handleDeleteButton(i) {
        return (evt) => {
            this.setState(prevState => {
                prevState.projectList.splice(i, 1);
                // localStorage.setItem("projetsSoutenus", JSON.stringify(prevState))
                return prevState;
            }, () => localStorage.setItem("projetsSoutenus", JSON.stringify(this.state.projectList)))

            // localStorage.setItem("projetsSoutenus", JSON.stringify(this.state.projectList))
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({projectList: nextProps.projetsSoutenus})
    }


    render() {
        return (
            <div className="payment" style={{paddingTop: "1em"}}>
                {/*<div className="payment-title" >*/}
                {/*    Dons*/}
                {/*</div>*/}
                {/*<hr style={{*/}
                {/*    width: "40%",*/}
                {/*    margin: "1em 30% 2em 30%",*/}
                {/*    border: "1.2px solid var(--semenka-green)",*/}
                {/*    backgroundColor: "var(--semenka-green)"*/}
                {/*}}/>*/}
                <div className={"payment-container"}>
                    <div>
                        <ShoppingCartOutlinedIcon color={"inherit"}/>
                    </div>
                    <div className={"payment-projets"}>
                        <div style={{
                            textAlign: "start",
                            marginBottom: "0.8em",
                            fontSize: "1.2em",
                            fontWeight: "600"
                        }}>Les projets que vous souhaitez soutenir :
                        </div>
                        {this.state.projectList.length === 0 ?
                        <div style={{fontSize: "1em", color: "grey"}}>Pour soutenir un projet, cliquez sur "nous
                            soutenir" en bas de l'article correspondant.</div> : []}
                        {this.state.projectList.map((elem, i) => (
                            <div key={i} className={"payment-projet"}>
                                <div className="payment-projet-title-div">
                                    {elem.isMandatory ?
                                        <div style={{width:"1.75em"}} /> :
                                        <IconButton aria-label="delete" size={"small"} onClick={this.handleDeleteButton(i)}>
                                            <DeleteIcon fontSize="small"/>
                                        </IconButton>
                                    }
                                    <p className={"payment-projet-title"}>• {elem.title}</p>
                                </div>
                                <PriceSlider value={elem.montant} setValue={this.valueSetter(i)}/>
                            </div>)
                        )}

                        <hr style={{
                            width: "70%",
                            margin: "1em 5% .1em 25%",
                            border: ".6px solid black",
                            backgroundColor: "black"
                        }}/>
                        <div style={{
                            textAlign: "right",
                            marginRight: "5%"
                        }}>{this.totalAsString(this.state.projectList)}</div>

                        <div style={{alignSelf: "start"}}>
                            <Button size={"small"} startIcon={<ArrowBackIosIcon/>} variant="outlined"
                                    onClick={() => this.props.setHeaderAccueil(true)}>
                                Découvrir d’autres projets
                            </Button>
                        </div>
                    </div>

                    <PaymentButton amount={this.total(this.state.projectList)} isShown={false}/>
                </div>

            </div>
        );
    }
}

export default Payment;