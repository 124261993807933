import axios from "axios";
import {headers, url} from "./Url";

export async function setVisible(articleId, isVisible) {
    let result = {};
    let error = "";
    let data = new FormData();
    data.set("token", localStorage.getItem("tokenAdmin"));
    data.set("articleId", articleId);
    data.set("isVisible", isVisible);
    const config = {
        method: "post",
        headers: headers,
        url: url+"/article/setVisible",
        data: data
    };
    try {
        result = (await axios(config)).data;
        // console.log(result)
    } catch (e) {
        console.log("erreur");
        // console.log(e)
        error = e.toString();
    }
    if (result.error === "401") localStorage.removeItem("tokenAdmin");
    if(result.token !== undefined) localStorage.setItem("tokenAdmin", result.token);

    return {result: result, error: error};
}

export async function createArticle(title, category) {
    let result = {};
    let error = "";
    let data = new FormData();
    data.set("token", localStorage.getItem("tokenAdmin"));
    data.set("title", title);
    data.set("category", category);
    const config = {
        method: "post",
        headers: headers,
        url: url+"/article/newArticle",
        data: data
    };
    try {
        result = (await axios(config)).data;
        // console.log(result)
    } catch (e) {
        console.log("erreur");
        // console.log(e)
        error = e.toString();
    }
    if (result.error === "401") localStorage.removeItem("tokenAdmin");
    if(result.token !== undefined) localStorage.setItem("tokenAdmin", result.token);
    return {result: result, error: error};
}

export async function updateArticle(article) {
    let result = {};
    let error = "";
    let data = new FormData();
    data.set("token", localStorage.getItem("tokenAdmin"));
    data.set("article", JSON.stringify(article));
    const config = {
        method: "post",
        headers: headers,
        url: url+"/article/update",
        data: data
    };
    try {
        result = (await axios(config)).data;
        // console.log(result)
    } catch (e) {
        console.log("erreur");
        // console.log(e)
        error = e.toString();
    }
    if (result.error === "401") localStorage.removeItem("tokenAdmin");
    if(result.token !== undefined) localStorage.setItem("tokenAdmin", result.token);
    return {result: result, error: error};
}

export async function pictureAdd(urlImg) {
    let result = {};
    let error = "";
    let data = new FormData();
    data.set("token", localStorage.getItem("tokenAdmin"));
    data.set("url", urlImg.toString());
    const config = {
        method: "post",
        headers: headers,
        url: url+"/picture/add",
        data: data
    };
    try {
        result = (await axios(config)).data;
    } catch (e) {
        error = e.toString();
    }
    if (result.error === "401") localStorage.removeItem("tokenAdmin");
    if(result.token !== undefined) localStorage.setItem("tokenAdmin", result.token);
    return {result: result, error: error};
}

export async function removeArticle(articleId) {
    let result = {};
    let error = "";
    let data = new FormData();
    data.set("token", localStorage.getItem("tokenAdmin"));
    data.set("articleId", articleId);
    const config = {
        method: "post",
        headers: headers,
        url: url+"/article/removeArticle",
        data: data
    };
    try {
        result = (await axios(config)).data;
    } catch (e) {
        error = e.toString();
    }
    if (result.error === "401" || result.error === 401) localStorage.removeItem("tokenAdmin");
    if(result.token !== undefined) localStorage.setItem("tokenAdmin", result.token);
    return {result: result, error: error};
}
