import axios from "axios";
import {url, headers} from "./Url";

export const getProjets = async (fromAdmin=false) => {
    let result = {};
    let data = new FormData();
    data.set("fromAdmin", fromAdmin);
    const config = {
        method: "post",
        headers: headers,
        url: url+"/article/getAll",
        data:data
    };
    try {
        result = (await axios(config)).data;
    } catch (e) {
        console.log("erreur");
        // console.log(e)
    }
    return result;
}

export const getOneProjet = async (id) => {
    let result = {};
    let error = "";
    let data = new FormData();
    data.set("token", localStorage.getItem("tokenAdmin"));
    const config = {
        method: "post",
        headers: headers,
        url: url+"/article/getArticle/" + id,
        data:data
    };
    try {
        result = (await axios(config)).data;
        // console.log(result);
    } catch (e) {
        console.log("erreur");
        // console.log(e)
        error = e;
    }
    return {result: result, error: error};
}

export const getImages = async () => {
    let result = [];
    const config = {
        method: "post",
        headers: headers,
        url: url+"/article/getAllImages"
    };
    try {
        result = (await axios(config)).data;
    } catch (e) {
        console.log("erreur");
        // console.log(e)
    }
    return result;
}

