import {useParams} from "react-router-dom";
import React from "react";
import {getImages, getOneProjet} from "../Actions/RequestGet";
import CircularProgress from "@material-ui/core/CircularProgress";
import "../../CSS/EditArticle.css";
import Button from "@material-ui/core/Button";
import {updateArticle} from "../Actions/RequestSet";
import {ReactComponent as Delete} from "../../Ressources/trash.svg";
import {ReactComponent as SideChanger} from "../../Ressources/change_side.svg";
import {ReactComponent as Edit} from "../../Ressources/edit.svg";
import PictureEdit from "../Utils/PictureEdit";


export default function EditArticle(props) {
    const {id} = useParams();
    return <EditArticleComp id={id} {...props} />;
}

export class EditArticleComp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            article: {},
            error: "",
            newArticle: {},
            pitureHovered: -1,
            allImages: [],
            pictureEdit: -1
        }
        this.handleTitleChange.bind(this);
    }

    /***************** todo ***********************/

    /* modif picturefirst, suppr url photo, test photo*/

    componentDidMount() {
        getOneProjet(this.props.id).then((data) => {
            this.setState({
                article: data.result,
                error: data.error.toString(),
                newArticle: data.result,
            })
        });

        getImages().then(data => {
            this.setState({
                allImages: data
            })
        })
    }


    handleTitleChange = (evt) => {
        const t = evt.target.innerText;
        this.setState((prevState) => ({newArticle: {...prevState.newArticle, title: t}}))
    }

    handleDescriptionChange = (evt) => {
        const t = evt.target.innerText;
        this.setState((prevState) => ({newArticle: {...prevState.newArticle, description: t}}))
    }

    handleParagraphTitleChange = (evt, index) => {
        const t = evt.target.innerText;
        // console.log("title change")
        this.setState(prevState => {
            const paragraphs = prevState.newArticle.paragraphs;
            paragraphs[index].title = t;
            return {newArticle: {...prevState.newArticle, paragraphs: paragraphs}}
        })
    }

    handleParagraphTextChange = (evt, index) => {
        const t = evt.target.innerText;
        // console.log("text change")
        this.setState(prevState => {
            prevState.newArticle.paragraphs[index].content = t;
            return {newArticle: {...prevState.newArticle, paragraphs: prevState.newArticle.paragraphs}}
        })
    }

    handleUpdateClick = () => {
        // console.log(this.state.newArticle)
        updateArticle(this.state.newArticle).then(data => {
            // console.log(data)
            if (data.error === "200") {
                this.setState({});
                this.componentDidMount();
            }
        })
    }


    editPicture = (i) => ([
        <Edit className="icon" key={0} onClick={() => {
            this.setState({pictureEdit: i})
        }}/>,
        <SideChanger className="icon" key={1} onClick={() => {
            this.setState(prevState => {
                const paragraphs = prevState.newArticle.paragraphs;
                const actualSide = paragraphs[i].pictureSide;
                paragraphs[i].pictureSide = actualSide === "right" ? "left" : "right";
                return {newArticle: {paragraphs: paragraphs, ...prevState.newArticle}};
            })
        }}/>,
        <Delete className="icon" key={2} onClick={() => {
            this.setState(prevState => {
                const paragraphs = prevState.newArticle.paragraphs;
                paragraphs[i].hasPicture = false;
                paragraphs[i].picture = null
                paragraphs[i].pictureId = null
                paragraphs[i].pictureSide = null
                return {newArticle: {paragraphs: paragraphs, ...prevState.newArticle}};
            })
        }}/>
    ])


    render() {
        const article = this.state.newArticle;
        // console.log(article);
        return Object.entries(article).length === 0 ?
            <div>
                <CircularProgress style={{marginTop: "10%", height: "5em", width: "5em"}}/>
                {this.state.error}
            </div>
            :
            <div className={"edit-article"}>
                <div className={"back-from-edit-article"}>{this.props.children}</div>
                {this.state.pictureEdit >= 0 ?
                    <PictureEdit onClose={() => this.setState({pictureEdit: -1})}
                                 allImages={this.state.allImages}
                                 forceUpdate={() => this.componentDidMount()}
                                 onPictureClick={(hasPicture, pictureId, pictureUrl) => {
                                     this.setState(prevState => {
                                         const paragraphs = prevState.newArticle.paragraphs;
                                         paragraphs[prevState.pictureEdit].hasPicture = hasPicture;
                                         if (hasPicture) {
                                             paragraphs[prevState.pictureEdit].pictureId = pictureId;
                                             paragraphs[prevState.pictureEdit].pictureSide = "right";
                                             paragraphs[prevState.pictureEdit].picture = pictureUrl;
                                         }
                                         return prevState;
                                     })
                                 }}/>
                    : []}

                {this.state.pictureEdit === -2 ?
                    <PictureEdit onClose={() => this.setState({pictureEdit: -1})}
                                 allImages={this.state.allImages}
                                 forceUpdate={() => this.componentDidMount()}
                                 onPictureClick={(hasPicture, pictureId, pictureUrl) => {
                                     this.setState(prevState => {
                                         prevState.newArticle.pictureId = pictureId
                                         prevState.newArticle.picture = pictureUrl
                                         return {newArticle: {pictureId, ...prevState.newArticle}}
                                     })
                                 }}
                    />
                    : []}
                <div className={"article-title div-to-edit"} contentEditable={true}
                     suppressContentEditableWarning={true}
                     onBlur={this.handleTitleChange}
                >
                    {article.title}
                </div>

                <div className={"article-picture-first div-to-edit"}
                     onClick={() => this.setState({pictureEdit: -2})}
                     style={{backgroundImage: "url(" + article.picture + ")"}}/>

                <div className={"article-description div-to-edit"} contentEditable={true}
                     suppressContentEditableWarning={true}
                     onBlur={this.handleDescriptionChange}>
                    {article.description}
                </div>
                {article.paragraphs.map((elem, i) => {
                    // console.log(i+"->"+elem.pictureSide)
                    return <div key={i} className={"paragraphs"}>
                        <div className="paragraph-title-div">
                            <div className="paragraph-title div-to-edit" contentEditable={true}
                                 style={{flexGrow: "2"}}
                                 suppressContentEditableWarning={true}
                                 onBlur={(event => this.handleParagraphTitleChange(event, i))}>
                                {elem.title}
                            </div>
                            <Delete className="del-icon" onClick={() => this.setState(prevState => {
                                prevState.newArticle.paragraphs.splice(i, 1);
                                return prevState;
                            })}/>
                        </div>

                        <div className={"paragraph-content"}
                             style={{flexDirection: (elem.hasPicture && elem.pictureSide === "right") ? "row" : "row-reverse"}}>
                            <div className="paragraph-text div-to-edit" contentEditable={true}
                                 style={{whiteSpace: "pre-line"}}
                                 suppressContentEditableWarning={true}
                                 onBlur={event => this.handleParagraphTextChange(event, i)}>
                                {elem.content}
                            </div>

                            <div style={{backgroundImage: "url(" + (elem.hasPicture ? elem.picture : "") + ")"}}
                                 className={(elem.hasPicture ? "" : "paragraph-picture-none ") + "paragraph-picture div-to-edit"}
                                 onMouseEnter={() => this.setState({pictureHovered: i})}
                                 onMouseLeave={() => this.setState({pictureHovered: -1})}
                            >
                                {this.state.pictureHovered === i ? this.editPicture(i) : []}
                            </div>
                        </div>
                        <hr style={{
                            margin: "1em 5em 3em 5em",
                            border: "1.2px solid var(--semenka-blue)",
                            backgroundColor: "var(--semenka-blue)"
                        }}/>
                    </div>
                })}
                <Button style={{marginBottom: "1em"}}
                        size="large"
                        variant="outlined"
                        onClick={() => this.setState(prevState => {
                            const paragraphs = prevState.newArticle.paragraphs;
                            paragraphs.push({
                                id: 0,
                                title: "nouveau paragraph",
                                hasPicture: false,
                                pictureSide: null,
                                picture: null,
                                pictureId: null,
                                content: "contenu"
                            })
                            return {newArticle: {paragraphs, ...prevState.newArticle}}
                        })}>Ajouter un paragrapah</Button>
                <br/>
                <Button variant={"contained"}
                        color="primary"
                        style={{marginBottom: "5em", height: "3.5em", width: "10em"}}
                        onClick={this.handleUpdateClick}>
                    Update
                </Button>
            </div>
    }

}