import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import AppMain from './AppMain';
import * as serviceWorker from './serviceWorker';
import AppAdmin from "./AppAdmin";


if(document.getElementById('root-react-main')) {
    ReactDOM.render(
        // <React.StrictMode>
            <AppMain />
        /*</React.StrictMode>*/,
        document.getElementById('root-react-main')
    );
}

if(document.getElementById('root-react-admin')) {
    ReactDOM.render(
        <React.StrictMode>
            <AppAdmin/>
        </React.StrictMode>,
        document.getElementById('root-react-admin')
    );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
