import React from "react";
import DisconnectButton from "../Utils/DisconnectButton";
import "../../CSS/Admin.css"
import ConnexionPopup from "../Utils/ConnexionPopup";
import {getProjets} from "../Actions/RequestGet";
import {categories} from "./Main";
import {ReactComponent as Edit} from "../../Ressources/edit.svg";
import {ReactComponent as Visible} from "../../Ressources/eye.svg"
import {ReactComponent as NonVisible} from "../../Ressources/hide.svg"
import {ReactComponent as Delete} from "../../Ressources/trash.svg";
import {createArticle, removeArticle, setVisible} from "../Actions/RequestSet";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from '@material-ui/lab/Alert';
import {EditArticleComp} from "./EditArticle";

export default class Admin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            projets: {},
            images: [],
            itemHovered: "0",
            imageHovered: null,
            imageSelected: null,
            creatingArticle: false,
            newArticleTitle: "nouvel article",
            newArticleCategory: 1,
            popUp: false,
            articleEditId: 0
        }
    }

    componentDidMount() {
        // getImages().then(result => {
        //     this.setState({images: result})
        // });
        getProjets(true).then(result => {
            this.setState({projets: result})
        });
    }

    handleVisibilityClick = (id, isVisible) => {
        // console.log("article" + id + " -> " + isVisible)
        setVisible(id, isVisible).then(data => {
            if (data.result.error === "401") {
                // console.log("ERROR notif")
                this.setState({popUp: true})
            } else {
                this.componentDidMount();
            }
        });
    }

    handleCreateArticleClick = () => {
        createArticle(this.state.newArticleTitle, this.state.newArticleCategory).then(result => {
                // console.log(result)
                this.setState({createArticle: false})
                this.componentDidMount();
            }
        )
    }

    handleEditArticleClick(id) {
        this.setState({articleEditId: id})
    }


    render() {
        return (<div className={"admin-main-container"}>
            {localStorage.getItem("tokenAdmin") ?
                (
                    <div>
                        <DisconnectButton/>
                        <div className="admin-bienvenu">
                            Bienvenu sur le BackOffice de Semenka
                        </div>
                        <br/>
                    </div>
                )
                :
                <ConnexionPopup/>}

            {this.state.articleEditId === 0 ?
                (<div className="admin-infos">
                    <div className={"admin-articles"}>
                        <div className={"admin-create-article"}>
                            <Button variant="contained"
                                    color="primary"
                                    onClick={() => this.setState(prevState => ({creatingArticle: !prevState.creatingArticle}))}>
                                Créer un nouvel article
                            </Button>

                            {this.state.creatingArticle ? [
                                    <TextField variant={"outlined"} label="Titre de l'article" required key={1}
                                               onChange={(evt) => this.setState({newArticleTitle: evt.target.value})}>
                                        Titre de l'article
                                    </TextField>,
                                    <FormControl variant="outlined" key={2}>
                                        <InputLabel>Catégorie</InputLabel>
                                        <Select
                                            onChange={(evt) => {
                                                this.setState({newArticleCategory: evt.target.value})
                                            }}>
                                            {/*todo warnings */}
                                            <MenuItem value={"1"}>SANTE</MenuItem>
                                            <MenuItem value={"2"}>NUTRITION</MenuItem>
                                            <MenuItem value={"3"}>BIODIVERSITÉ</MenuItem>
                                            <MenuItem value={"4"}>ENTREPRENEURIAT</MenuItem>
                                        </Select>
                                    </FormControl>,
                                    <Button variant="outlined" onClick={this.handleCreateArticleClick} key={3}>
                                        Créer l'article
                                    </Button>]
                                : []}
                        </div>

                        {Object.entries(this.state.projets).length !== 0 ?
                            Object.keys(this.state.projets).map((key1) => (
                                [<div key={"cat" + key1} className={"admin-infos-cat"}>
                                    {categories[key1 - 1].name}
                                </div>,
                                    ...Object.keys(this.state.projets[key1]).map((key2) => {
                                        const elem = this.state.projets[key1][key2];
                                        return <div key={key1 + key2}
                                                    className={"admin-infos-item"}
                                                    onClick={() => this.setState({itemHovered: key1 + key2})}
                                                    onMouseEnter={() => this.setState({itemHovered: key1 + key2})}
                                                    onMouseLeave={() => this.setState({itemHovered: "0"})}>
                                            <div className={"admin-infos-item-text"}
                                                 style={{color: elem.isDisplay ? "black" : "grey"}}>
                                                {elem.title} (n°{elem.id})
                                            </div>
                                            {this.state.itemHovered === key1 + key2 ?
                                                <div className={"item-svg-div-div"}>
                                                    {/*<a href={"/projets-admin-edit/" + elem.id}>*/}
                                                    {/*    <div className={"item-svg-div"}>*/}
                                                    {/*        <Edit className={"item-svg"}/>*/}
                                                    {/*    </div>*/}
                                                    {/*</a>*/}
                                                    <div onClick={() => this.handleEditArticleClick(elem.id)}>
                                                        <div className={"item-svg-div"}>
                                                            <Edit className={"item-svg"}/>
                                                        </div>
                                                    </div>
                                                    {elem.isDisplay ?
                                                        <div className={"item-svg-div"}
                                                             onClick={() => this.handleVisibilityClick(elem.id, false)}>
                                                            <Visible className={"item-svg"}/>
                                                        </div>
                                                        :
                                                        <div className={"item-svg-div"}
                                                             onClick={() => this.handleVisibilityClick(elem.id, true)}>
                                                            <NonVisible className={"item-svg"}/>
                                                        </div>
                                                    }
                                                    <div className={"item-svg-div"} onClick={() => {
                                                        removeArticle(elem.id).then(data => {
                                                            // console.log(data)
                                                            if (data.result.error === "401" || data.result.error === 401) {
                                                                // console.log("ERROR notif")
                                                                this.setState({popUp: true})
                                                            } else {
                                                                this.componentDidMount();
                                                            }
                                                        });
                                                    }}>
                                                        <Delete className="icon"/>
                                                    </div>
                                                </div>
                                                : <div style={{width: "4em"}}/>}
                                        </div>
                                    })]
                            ))
                            : <div/>
                        }
                    </div>
                </div>)
                :
                (<EditArticleComp id={this.state.articleEditId}>
                    <Button variant={"contained"}  onClick={() => this.setState({articleEditId: 0})}>Retourner a la liste des articles</Button>
                </EditArticleComp>)
            }

            <Snackbar open={this.state.popUp}
                      autoHideDuration={5000}
                      onClose={(event, reason) => {
                          this.setState({popUp: false})
                      }}>
                <Alert onClose={(event, reason) => {
                    this.setState({popUp: false})
                }} severity="error" variant={"filled"}>
                    Error: Deconnectez-vous, puis reconnectez-vous
                </Alert>
            </Snackbar>
        </div>)
    }
}