import axios from "axios";
import {headers, url} from "./Url";

export async function connexion(user,pass) {
    let result = {};
    let error = "";
    let data = new FormData();
    data.set("user",user);
    data.set("password",pass)
    const config = {
        method: "post",
        headers: headers,
        url: url+"/auth",
        data:data
    };
    try {
        result = (await axios(config)).data;
    } catch (e) {
        console.log("erreur");
        // console.log(e)
        error = e.toString();
    }
    return {result:result,error:error};
}
