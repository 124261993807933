import React from "react";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from '@material-ui/icons/Cancel';
import AddCircleOutlinedIcon from '@material-ui/icons/AddCircleOutlined';
import "../../CSS/PictureEdit.css";
import TextField from "@material-ui/core/TextField";
import {pictureAdd} from "../Actions/RequestSet";

export default function PictureEdit({allImages, onClose, onPictureClick, forceUpdate, ...props}) {
    var pictureNew = "";
    return <div className="picture-edit-container">
        <p className="p-t">Select a picture </p>
        <IconButton onClick={onClose} className="close-button">
            <CancelIcon fontSize="large"/>
        </IconButton>
        <div className="images-container">
            {allImages.map((elem, i) => (
                <div key={i} className="img-div" onClick={() => onPictureClick(true, elem.id, elem.url)}>
                    {elem.url}
                </div>)
            )}
        </div>
        <div className="add-div">
            <TextField variant="outlined" label="URL" size="small"
                       helperText="Entrez l'url de l'image souhaitée puis cliquez sur +"
                       onChange={(e) => pictureNew = e.target.value}
                       defaultValue={pictureNew}/>
            <IconButton onClick={() => {
                if (pictureNew !== "") pictureAdd(pictureNew).then(() => {
                    forceUpdate()
                    document.getElementsByTagName("input")[0].value = ""
                })
            }}>
                <AddCircleOutlinedIcon color="primary" fontSize="large"/>
            </IconButton>
        </div>
    </div>
}