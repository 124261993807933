import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import {connexion} from "../Actions/Auth";

const style = {
    container: {
        position: "fixed",
        backgroundColor: "rgb(250,250,250)",
        top: "0",
        left: "0",
        height: "100vh",
        width: "100vw",
        opacity: "0.95",
        zIndex:"99"
    },
    form: {
        margin: "20%",
        border: "1px solid grey",
        borderRadius: "4px",
        display: "flex",
        flexDirection: "column",
        padding: "1% 10% 1% 10%",
        minHeight: "30vh",
        justifyContent: "space-around",
        maxWidth:"40em"
    }
}

export default class ConnexionPopup extends React.Component {
    constructor(props) {
        super(props);
        this.username = "";
        this.password = "";
        this.state = {
            error: "",
            isConnected: false,
            badUserPass: false
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        // console.log("submit " + this.username + " " + this.password);
        connexion(this.username, this.password).then(data => {
            // console.log(data.error);
            this.setState({error: data.error})
            if (!data.result.isConnected) {
                this.setState({badUserPass: true})
            } else {
                this.setState({isConnected:true})
                localStorage.setItem("tokenAdmin", data.result.token)
            }
        })
    }

    handleChangeUser = (event) => {
        this.username = event.target.value
    }

    handleChangePassword = (event) => {
        this.password = event.target.value
    }

    render() {
        if(!this.state.isConnected) {
        return <div style={style.container}>
            <form onSubmit={this.handleSubmit}
                  method={"POST"}
                  style={style.form}
            >
                <TextField
                    type="text"
                    onChange={this.handleChangeUser}
                    name="username"
                    label="username"
                    error={this.state.badUserPass}
                    variant="outlined"
                />
                <TextField
                    type="password"
                    onChange={this.handleChangePassword}
                    name="password"
                    label="password"
                    error={this.state.badUserPass}
                    variant="outlined"
                />
                <Button type="submit">
                    Connexion
                </Button>

            </form>
        </div>}
        return [];
    }

}