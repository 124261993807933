import React from 'react';
import './App.css';
import "./CSS/Main.css";
import Admin from "./Components/Sections/Admin";

function AppAdmin() {
    return (<Admin />)
}

export default AppAdmin;
