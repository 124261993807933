import React from "react";
import {CSSTransition} from "react-transition-group";
import Categories from "./Categories";
import Projets from "./Projets";
import "../../CSS/CSSTransitions.css";
import ProjetArticle from "./ProjetArticle";
import {getOneProjet, getProjets} from "../Actions/RequestGet";
import BackgroundSante from "../../Ressources/categorie_sante.jpg";
import BackgroundNutrition from "../../Ressources/categorie_nutrition.jpg";
import BackgroundEducation from "../../Ressources/categorie_entrepreunariat.jpg";
import BackgroundBiodiversite from "../../Ressources/categorie_biodiversite.jpg";

export default class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            window: "categories",
            categorie: "",
            articleId: "",
            projetsFromCategorie: true,
            projets: {},
            errorProjets: "",
            articleProjet: {}
        }
    }

    findGetParameter = (parameterName) => {
        var result = null,
            tmp = [];
        window.location.search.substr(1).split("&")
            .forEach(function (item) {
                tmp = item.split("=");
                if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
            });
        return result;
    }


    componentDidMount() {
        const categorie = this.findGetParameter("categorie");
        if(parseInt(categorie)<=4 && parseInt(categorie)>0) {
            setTimeout(()=>this.onCategorieClick(categorie), 100);

        }
        getProjets().then((result) => {
            // console.log(result)
            this.setState({projets: result});
        });
    }

    onCategorieClick = (categorie) => {
        this.setState({window: "projets", categorie: categorie, projetsFromCategorie: true})
    }

    onBackClickFromProjets = () => {
        this.setState({window: "categories", categorie: "", projetsFromCategorie: true})
    }

    onBackClickFromArticle = () => {
        this.setState({window: "projets", articleId: "", projetsFromCategorie: false, articleProjet: {}})
    }

    onProjetClick = (articleId) => {
        this.setState({window: "article", articleId: articleId, projetsFromCategorie: false});
        getOneProjet(articleId).then((data => {
            // console.log(data)
            this.setState({articleProjet: data.result})
        }))
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.headerAccueil === true && this.state.window !== "categories") {
            this.props.setHeaderAccueil(false);
            this.onBackClickFromArticle()
            this.onBackClickFromProjets()
        } else if (this.props.headerAccueil === true && this.state.window === "categories") {
            this.props.setHeaderAccueil(false);
        }
    }


    render() {
        // console.log(this.state.window);
        return <div id="main-container">
            <CSSTransition in={this.state.window === "categories"}
                           timeout={1000}
                           classNames="categoriesCSS"
                           unmountOnExit>

                <Categories onCategorieClick={this.onCategorieClick}/>
            </CSSTransition>

            <CSSTransition in={this.state.window === "projets" || this.state.window === "projets-from-article"}
                           timeout={1000}
                           classNames={(this.state.projetsFromCategorie) ? "projetsCSS" : "projetsCSS-from-article"}
                           unmountOnExit>
                <Projets categorie={this.state.categorie}
                         projets={this.state.projets}
                         onBackClick={this.onBackClickFromProjets}
                         onProjetClick={this.onProjetClick}/>
            </CSSTransition>

            <CSSTransition in={this.state.window === "article"}
                           timeout={1000}
                           classNames="articleCSS"
                           unmountOnExit>
                <ProjetArticle articleId={this.state.articleId}
                               articleProjet={this.state.articleProjet}
                               onBackClick={this.onBackClickFromArticle}
                               setProjetsSoutenus={this.props.setProjetsSoutenus}/>
            </CSSTransition>

        </div>
    }

}

export const categories = [
    {
        name: "SANTÉ",
        description: "Dispensaires, soins primaires, opérations, phytothérapies",
        background: BackgroundSante
    },
    {
        name: "NUTRITION",
        description: "Productions agricoles, unités de transformation, pratiques respectueuses,  qualités nutritionnelles ",
        background: BackgroundNutrition
    },
    {
        name: "BIODIVERSITÉ",
        description: "Pépinières, plantations, préservations, sensibilisation, circularité",
        background: BackgroundBiodiversite
    },
    {
        name: "EDUCATION ET ENTREPRENEURIAT",
        description: "Ecoles, formations pratiques inclusif, coopération, innovation",
        background: BackgroundEducation
    },
]

