import React from "react";
import "../../CSS/Projets.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import {categories} from "./Main";
import {ReactComponent as BackArrow} from "../../Ressources/back_arrow.svg";


function specialChars(str) {
    return (new DOMParser()).parseFromString(`<!doctype html><body>${str}`,'text/html').body.textContent
}


export default class Projets extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            categorie: parseInt(props.categorie),
            projetHovered: 0
        }
        this.fixedRandom = (new Array(20)).fill().map(() => {
            return Math.floor(Math.random() * 4 + 2)
        });
        const update = () => this.forceUpdate();
        window.addEventListener('resize',update);
    }

    projects = () => {
        return (this.props.projets[this.state.categorie].map((element, i) => {
            const bg = element.picture;
            const size = "card-wide-"+ this.fixedRandom[i];
            return (<div key={i}
                         className={"projet card " + size}
                         onMouseEnter={() => this.setState({projetHovered: element.id})}
                         onMouseLeave={() => this.setState({projetHovered: 0})}
                         onClick={() => this.props.onProjetClick(element.id)}
                         style={{
                             backgroundImage: "url(" + bg + ")",
                         }}>
                            <span className="projet-title">
                                {specialChars(element.title)}
                            </span>
                <br/>
                <span className="projet-description"
                      hidden={this.state.projetHovered !== element.id}>
                                {specialChars(element.description)}
                            </span>
            </div>)
        }))
    }

    correctPosition = () => {
        let count = 3;
        let nbcol = parseInt(this.getCol());
        let nbCard = this.props.projets[this.state.categorie].length;
        // console.log("nbcol:"+nbcol+"  nbcard:"+nbCard)
        for (let i=0;i<nbCard;i++) {
            count+=this.fixedRandom[i];
            if (count===nbcol) {
                // console.log("egalite a "+count);
                count=0;
            } else if(count>nbcol) {
                // console.log(this.fixedRandom[i] + "doit changer (count="+count+" en");
                this.fixedRandom[i] = nbcol - (count - this.fixedRandom[i]);
                if (this.fixedRandom[i] === 1 && this.fixedRandom[i-1] > 2) {
                    this.fixedRandom[i-1] -= 1;
                    this.fixedRandom[i] += 1;
                } else if (i>=2 && this.fixedRandom[i] === 1 && this.fixedRandom[i-1] === 1 && this.fixedRandom[i-2] > 2) {
                    this.fixedRandom[i-2] -= 1;
                    this.fixedRandom[i] += 1;
                }

                // console.log(this.fixedRandom[i])
                count = 0;
            }
        }
        // console.log(this.fixedRandom);
    }

    categorie = () => {
        return (<div className={"projets-categorie card card-wide-3"}>
            <span className={"projets-categorie-title"}>
                {categories[this.props.categorie - 1] && categories[this.props.categorie - 1].name}
            </span>
            <br/>
            <div style={{height: "1rem"}}/>
            <span className={"projets-categorie-description"}>
                    {categories[this.props.categorie - 1] && categories[this.props.categorie - 1].description}
            </span>
        </div>)
    }

    getCol = () => {
        const gap = 15;
        const minW = 100;
        let Wc = 1920;
        try {
            Wc = document.querySelector('.projets-container').offsetWidth;
        } catch (e) {
            return "pas de grid"
        }
        return Math.floor((Wc - 60 + gap) / (minW + gap));
    }


    render() {
        if (Object.entries(this.props.projets).length !==0) this.correctPosition();
        return (<div style={{minHeight: "40vh", width: "100vw"}}>
            <button onClick={this.props.onBackClick}
                    className={"back-button"}>
                <BackArrow className={"back-button-logo"}/>
            </button>
            <div className="projets-container">

                {this.categorie()}

                {Object.entries(this.props.projets).length !== 0 ?
                    this.projects()
                    : <CircularProgress style={{margin: "20%"}}/>}

            </div>
        </div>)
    }
}
