// export const url = "http://localhost:8000";
//export const url="http://192.168.0.162:8000";
// export const url="https://api.semenka.juniorisep.com";
export const url="https://semenka-api-juniorisep.herokuapp.com";

// export const url=window.location.href.includes("juniorisep.com")
//  ? "https://api.semenka.juniorisep.com"
//  : "http://127.0.0.1:8000";



export const headers = {};
// export const headers = {'Access-Control-Allow-Origin': '*'};
